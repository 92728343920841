<template>
  <v-container grid-list-xl>
    <v-flex xs4 right>
      <div
        class="FAB-Extended-PrimaryColor-Enabled"
        style="margin-top: 15px"
        v-if="!isNewAnnouncement"
      >
        <v-btn type="submit" color="primary" @click="deleteAnnouncement">
          <v-icon class="mr-1" left>mdi-delete-outline</v-icon>DELETE ANNOUNCEMENT
        </v-btn>
      </div>
    </v-flex>
    <v-form ref="form" lazy-validation v-model="valid" class="settings" v-if="!loading">
      <v-layout row wrap>
        <v-flex xs8>
          <view-title />
        </v-flex>
        <v-flex xs12>
          <announcement-details
            :name.sync="announcement.name"
            :announcement_type.sync="announcement.type"
            :app.sync="announcement.app"
            :allowed_resources.sync="allowedResourcesProxy"
            :position.sync="announcement.position"
            :is_global.sync="announcement.is_global"
            :resource_key.sync="announcement.key"
            :active.sync="announcement.active"
            :isNewAnnouncement="isNewAnnouncement"
            :availableApps="availableApps"
            :multigroupMap="multigroupMap"
          />
        </v-flex>
        <v-flex xs12>
          <language-info
            language="English"
            :image_url.sync="announcement.info.en.image_url"
            :image_data.sync="images.en"
            :title.sync="announcement.info.en.title"
            :description.sync="announcement.info.en.description"
            :sub_text.sync="announcement.info.en.sub_text"
            :button_text.sync="announcement.info.en.button_text"
            ref="englishVersion"
          />
        </v-flex>
        <v-flex xs12 style="margin-bottom: 100px">
          <language-info
            language="French"
            :image_url.sync="announcement.info.fr.image_url"
            :image_data.sync="images.fr"
            :title.sync="announcement.info.fr.title"
            :description.sync="announcement.info.fr.description"
            :sub_text.sync="announcement.info.fr.sub_text"
            :button_text.sync="announcement.info.fr.button_text"
            ref="frenchVersion"
          />
          <span>
            Only 5 announcements can be created per location for a given time period. If you exceed
            this limit, it will not show up in the app.
          </span>
        </v-flex>
      </v-layout>
      <save-footer
        v-show="isModified"
        :cancelAction="cancel"
        :saveLabel="isNewAnnouncement ? 'Create Announcement' : 'Save Changes'"
        :saveAction="save"
      />
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import AnnouncementDetails from './details';
import LanguageInfo from './language';

export default {
  name: 'announcement-settings',
  components: {
    AnnouncementDetails,
    LanguageInfo,
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isModified) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data: () => ({
    valid: true,
    loading: false,
    announcementTemplate: {
      name: null,
      type: null,
      key: null,
      app: null,
      is_global: false,
      allowed_resources: [],
      position: 0,
      active: false,
      info: {
        en: {
          image_url: null,
          title: null,
          description: null,
          sub_text: null,
          button_text: null,
        },
        fr: {
          image_url: null,
          title: null,
          description: null,
          sub_text: null,
          button_text: null,
        },
      },
    },
    availableApps: [],
    multigroupMap: {},
    announcement: {
      name: null,
      type: null,
      key: null,
      app: null,
      is_global: false,
      allowed_resources: [],
      position: null,
      active: true,
      info: {
        en: {
          image_url: null,
          title: null,
          description: null,
          sub_text: null,
          button_text: null,
        },
        fr: {
          image_url: null,
          title: null,
          description: null,
          sub_text: null,
          button_text: null,
        },
      },
    },
    images: {
      en: null,
      fr: null,
      enBackup: null,
      frBackup: null,
    },
    announcementBackup: {},
  }),
  computed: {
    ...mapGetters('announcements', {
      getAnnouncements: 'getAnnouncements',
    }),
    ...mapGetters('adminPanel', ['isAdmin']),
    isModified() {
      return (
        !isEqual(this.announcement, this.announcementBackup) ||
        !isEqual(this.images.en, this.images.enBackup) ||
        !isEqual(this.images.fr, this.images.frBackup)
      );
    },
    isNewAnnouncement() {
      return (
        !this.$route.params.announcement_id ||
        this.$route.params.announcement_id === 'announcement-new'
      );
    },
    allowedResourcesProxy: {
      get() {
        return this.announcement.allowed_resources;
      },
      set(v) {
        this.$set(this.announcement, 'allowed_resources', v);
      },
    },
  },
  async mounted() {
    this.$store.commit('adminPanel/setLoading', true);
    this.loading = true;

    try {
      await this.fetchAnnouncements();
      const { data: apps } = await this.api.get(`/location/multigroup`);
      this.$set(
        this,
        'availableApps',
        apps.groups
          .filter((a) => {
            return ['Rogers', 'JJKitchen', 'Thrive', 'Nourish', 'Boost'].includes(a.name);
          })
          .map((a) => ({
            text: a.name,
            value: a.id,
          })),
      );
      const multigroupPromises = this.availableApps.map(async (app) => {
        const multigroup = await this.getLocationMultigroup({ id: app.value });
        this.$set(this.multigroupMap, app.value, { name: app.text, groups: multigroup.groups });
      });
      await Promise.all(multigroupPromises);
      const { announcement_id } = this.$route.params;
      if (!this.isNewAnnouncement) {
        this.$store.commit('adminPanel/setViewTitle', {
          depth: 1,
          title: 'Edit Announcement',
          to: { name: 'announcement-settings', params: { announcement_id } },
        });
        this.fetchAnnouncementData();
      } else {
        this.$store.commit('adminPanel/setViewTitle', {
          depth: 1,
          title: 'Create New Announcement',
          to: { name: 'announcement-new' },
        });
        this.$set(this, 'announcementBackup', cloneDeep(this.announcementTemplate));
        this.$set(this, 'announcement', cloneDeep(this.announcementTemplate));
      }
    } catch (e) {
      console.error(e);
      this.$toast.error('Failed loading Announcement info');
    }

    this.$store.commit('adminPanel/setLoading', false);
    this.loading = false;
  },
  methods: {
    ...mapActions('announcements', ['fetchAnnouncements']),
    ...mapActions('sites', ['getLocationMultigroup']),
    fetchAnnouncementData() {
      const announcements = this.getAnnouncements();
      const announcement = announcements.find((a) => a.id === this.$route.params.announcement_id);
      const polishedAnnouncement = Object.assign(
        cloneDeep(this.announcementTemplate),
        announcement,
      );

      polishedAnnouncement.info.fr =
        polishedAnnouncement.info.fr || this.announcementTemplate.info.fr;
      polishedAnnouncement.info.en =
        polishedAnnouncement.info.en || this.announcementTemplate.info.en;

      polishedAnnouncement.info.fr.image_url = polishedAnnouncement.info.fr.image_url || null;
      polishedAnnouncement.info.en.image_url = polishedAnnouncement.info.en.image_url || null;

      if (polishedAnnouncement.is_global) {
        polishedAnnouncement.allowed_resources = this.multigroupMap[
          polishedAnnouncement.key
        ].groups.map((g) => g.id);
      }

      this.images.en = polishedAnnouncement.info.en.image_url || null;
      this.images.fr = polishedAnnouncement.info.fr.image_url || null;
      this.images.enBackup = this.images.en;
      this.images.frBackup = this.images.fr;

      this.$set(this, 'announcement', cloneDeep(polishedAnnouncement));
      this.$set(this, 'announcementBackup', cloneDeep(polishedAnnouncement));
    },
    cancel() {
      // restore backups
      this.$set(this, 'announcement', cloneDeep(this.announcementBackup));
      this.$set(this.images, 'en', this.images.enBackup);
      this.$set(this.images, 'fr', this.images.frBackup);
      this.$refs.form.resetValidation();
      this.$toast('All your changes have been reset');
    },
    async save() {
      if (!this.valid || !this.$refs.form.validate()) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      const { is_global, allowed_resources } = this.announcement;
      if (!is_global && (!allowed_resources || !allowed_resources.length)) {
        this.$toast('Announcements need at least one assigned site');
        return;
      }
      try {
        await Promise.all([
          await this.$refs.englishVersion.save(),
          await this.$refs.frenchVersion.save(),
        ]);
        const announcementObj = cloneDeep(this.announcement);
        if (!this.isAdmin) announcementObj.is_global = false; // Disable global announcements for non-admins
        announcementObj.allowed_resources = announcementObj.is_global
          ? []
          : announcementObj.allowed_resources;
        const frInfo = announcementObj.info.fr;
        frInfo.button_text = frInfo.button_text || 'Dismiss';
        const enInfo = announcementObj.info.en;
        enInfo.button_text = enInfo.button_text || 'Dismiss';
        announcementObj.info = {
          fr: (this.hasAnyProps(frInfo) && frInfo) || null,
          en: (this.hasAnyProps(enInfo) && enInfo) || null,
        };
        if (!this.hasAnyProps(enInfo) && !this.hasAnyProps(frInfo)) {
          this.$toast('At least one version should exist');
          return;
        }
        let announcementPromise;
        // not a new announcement
        if (!this.isNewAnnouncement) {
          announcementPromise = this.api.put(
            `/announcement/${this.$route.params.announcement_id}`,
            announcementObj,
          );
        } else {
          announcementPromise = this.api.post(`/announcement`, announcementObj);
        }
        await announcementPromise;
        this.$set(this, 'announcementBackup', this.announcement);
        this.$set(this.images, 'enBackup', this.images.en);
        this.$set(this.images, 'frBackup', this.images.fr);
        this.$router.push({ name: 'announcement-listing' });
      } catch (e) {
        console.error(e);
        this.$toast('Could not save Announcement');
      }
    },
    async deleteAnnouncement() {
      try {
        const shouldDelete = await this.$confirm({
          title: 'Delete Announcement',
          message: 'Are you sure you want to delete this announcement?',
          buttonTrueText: 'DELETE',
          buttonFalseText: 'CANCEL',
        });
        if (shouldDelete) {
          this.$store.commit('adminPanel/setLoading', true);
          await this.api.delete(`/announcement/${this.$route.params.announcement_id}`);
          this.$router.push({ name: 'announcement-listing' });
          this.$store.commit('adminPanel/setLoading', false);
        }
      } catch (error) {
        console.error(error);
        this.$toast.error('Could not delete announcement. Please try again later.');
      }
    },
    hasAnyProps(announcementInfo) {
      const { image_url, title, description, button_text } = announcementInfo;
      return image_url || title || description || (button_text && button_text !== 'Dismiss');
    },
  },
};
</script>

<style scoped></style>

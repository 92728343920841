import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VCard, { staticStyle: { "min-height": "400px" } }, [
    _c(
      "div",
      { staticClass: "v-card-content" },
      [
        _c(
          VLayout,
          [
            _c(
              VFlex,
              { attrs: { xs9: "" } },
              [
                _c(
                  VLayout,
                  { attrs: { "justify-space-between": "" } },
                  [
                    _c(VFlex, [
                      _c("div", { staticClass: "H5-Primary-Left" }, [
                        _vm._v("Announcement Details")
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  VLayout,
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      VFlex,
                      { attrs: { xs8: "" } },
                      [
                        _c(VTextField, {
                          attrs: {
                            label: "Announcement Name",
                            value: _vm.name,
                            rules: _vm.nameRules
                          },
                          on: {
                            input: function($event) {
                              return _vm.$emit("update:name", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(VFlex, { attrs: { xs4: "" } }),
                    _c(
                      VFlex,
                      { attrs: { xs6: "" } },
                      [
                        _c(VSelect, {
                          attrs: {
                            label: "Announcement Type",
                            value: _vm.isAnnouncementTypeOther
                              ? "Other"
                              : _vm.announcement_type,
                            rules: _vm.typeRules,
                            items: _vm.announcementTypes
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateAnnouncementType($event)
                            }
                          }
                        }),
                        _vm.isAnnouncementTypeOther
                          ? _c(VTextField, {
                              attrs: {
                                label: "Type",
                                value: _vm.announcement_type,
                                rules: _vm.typeRules
                              },
                              on: {
                                input: function($event) {
                                  return _vm.$emit(
                                    "update:announcement_type",
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(VFlex, { attrs: { xs6: "" } }),
                    _c(
                      VFlex,
                      { attrs: { xs6: "" } },
                      [
                        _c(VSelect, {
                          attrs: {
                            label: "Select App",
                            value: _vm.resource_key,
                            rules: _vm.appRules,
                            items: _vm.availableApps
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateResourceKey($event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(VFlex, { attrs: { xs6: "" } }),
                    _c(
                      VFlex,
                      { attrs: { xs8: "" } },
                      [
                        _vm.resource_key
                          ? _c(
                              VLayout,
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-left": "25px" } },
                                  [_vm._v("Sites")]
                                ),
                                _c(
                                  VFlex,
                                  {
                                    staticStyle: {
                                      height: "68px",
                                      "padding-bottom": "0px"
                                    },
                                    attrs: { xs12: "" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { id: "site-search" } },
                                      [
                                        _c(VTextField, {
                                          staticStyle: {
                                            "border-radius": "0px !important"
                                          },
                                          attrs: {
                                            label:
                                              "Search Sites (" + _vm.app + ")",
                                            "prepend-inner-icon": "mdi-magnify",
                                            outline: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.search,
                                            callback: function($$v) {
                                              _vm.search = $$v
                                            },
                                            expression: "search"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  VFlex,
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      VLayout,
                                      {
                                        staticStyle: {
                                          "padding-left": "12px",
                                          "padding-right": "12px"
                                        },
                                        attrs: { row: "" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              overflow: "hidden",
                                              border: "solid 1px #757575",
                                              width: "100%"
                                            }
                                          },
                                          [
                                            _c(
                                              VList,
                                              { staticClass: "list-panel" },
                                              [
                                                _c(
                                                  VListTile,
                                                  [
                                                    _c(
                                                      VListTileAction,
                                                      [
                                                        _c(VCheckbox, {
                                                          attrs: {
                                                            value:
                                                              _vm.isGlobalProxy,
                                                            label: "Select All"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.isGlobalProxy,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.isGlobalProxy = $$v
                                                            },
                                                            expression:
                                                              "isGlobalProxy"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._l(
                                                  _vm.orderBy(
                                                    _vm.filterBy(
                                                      _vm.availableSites,
                                                      _vm.search,
                                                      "name"
                                                    ),
                                                    "name"
                                                  ),
                                                  function(site, siteIndex) {
                                                    return _c(
                                                      VListTile,
                                                      {
                                                        key: site.id,
                                                        attrs: {
                                                          id: siteIndex
                                                        },
                                                        on: {
                                                          click: function() {}
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          VListTileAction,
                                                          [
                                                            _c(VCheckbox, {
                                                              attrs: {
                                                                value: site.id,
                                                                label: site.name
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.updateIsGlobal
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.allowedResourcesProxy,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.allowedResourcesProxy = $$v
                                                                },
                                                                expression:
                                                                  "allowedResourcesProxy"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(VFlex, { attrs: { xs4: "" } }),
                    _c(VFlex, { attrs: { xs6: "" } }, [
                      _vm.resource_key
                        ? _c(
                            "div",
                            [
                              _vm.availablePositions.length > 0 &&
                              (_vm.active || _vm.isNewAnnouncement)
                                ? _c(VSelect, {
                                    attrs: {
                                      label: "Position",
                                      disabled: !_vm.app,
                                      value: this.position,
                                      rules: _vm.positionRules,
                                      items: _vm.availablePositions
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.$emit(
                                          "update:position",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _c("div", [
                                    _vm._v(
                                      "\n                Enable this announcement to see positions. If not possible, disable another one.\n              "
                                    )
                                  ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c(VFlex, { attrs: { xs6: "" } }),
                    _c(
                      VFlex,
                      { attrs: { xs6: "" } },
                      [
                        _c(VSelect, {
                          attrs: {
                            label: "Status",
                            disabled: _vm.availablePositions.length === 0,
                            value: _vm.active,
                            items: _vm.announcementActiveStates
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateStatus($event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(VFlex, { attrs: { xs6: "" } })
                  ],
                  1
                )
              ],
              1
            ),
            _c(VFlex, { attrs: { xs3: "" } })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VCard, { staticStyle: { "min-height": "400px" } }, [
    _c(
      "div",
      { staticClass: "v-card-content" },
      [
        _c(
          VLayout,
          { attrs: { row: "", wrap: "" } },
          [
            _c(
              VFlex,
              { attrs: { xs8: "" } },
              [
                _c(
                  VLayout,
                  { attrs: { "justify-space-between": "" } },
                  [
                    _c(VFlex, [
                      _c("div", { staticClass: "H5-Primary-Left" }, [
                        _vm._v(_vm._s(_vm.language) + " Version")
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  VLayout,
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      VFlex,
                      { attrs: { xs8: "" } },
                      [
                        _c(VTextField, {
                          attrs: {
                            label: "Title",
                            value: _vm.title,
                            rules: _vm.titleRules(!_vm.shouldValidate)
                          },
                          on: {
                            input: function($event) {
                              return _vm.$emit("update:title", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(VFlex, { attrs: { xs4: "" } })
                  ],
                  1
                ),
                _c(
                  VLayout,
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      VFlex,
                      {
                        staticStyle: {
                          "margin-bottom": "-15px",
                          "margin-left": "10px"
                        },
                        attrs: { xs12: "" }
                      },
                      [_vm._v("Description")]
                    ),
                    _c(
                      VFlex,
                      {
                        staticStyle: {
                          height: "68px",
                          "padding-bottom": "0px"
                        },
                        attrs: { xs8: "" }
                      },
                      [
                        _c(VTextarea, {
                          staticStyle: {
                            "border-radius": "0px !important",
                            "padding-bottom": "15px",
                            "margin-bottom": "-70px"
                          },
                          attrs: {
                            outline: "",
                            value: _vm.description,
                            rules: _vm.descriptionRules(!_vm.shouldValidate)
                          },
                          on: {
                            input: function($event) {
                              return _vm.$emit("update:description", $event)
                            }
                          }
                        }),
                        _c("div", { staticStyle: { "margin-left": "415px" } }, [
                          _vm._v(
                            _vm._s(_vm.getWordCount(_vm.description)) + "/200"
                          )
                        ])
                      ],
                      1
                    ),
                    _c(VFlex, { attrs: { xs4: "" } })
                  ],
                  1
                ),
                _c(
                  VLayout,
                  {
                    staticStyle: { "margin-top": "75px" },
                    attrs: { row: "", wrap: "" }
                  },
                  [
                    _c(
                      VFlex,
                      {
                        staticStyle: {
                          "margin-bottom": "-15px",
                          "margin-left": "10px"
                        },
                        attrs: { xs12: "" }
                      },
                      [_vm._v("Sub Text")]
                    ),
                    _c(
                      VFlex,
                      {
                        staticStyle: {
                          height: "68px",
                          "padding-bottom": "0px"
                        },
                        attrs: { xs8: "" }
                      },
                      [
                        _c(VTextarea, {
                          staticStyle: {
                            "border-radius": "0px !important",
                            "padding-bottom": "15px",
                            "margin-bottom": "-70px"
                          },
                          attrs: {
                            outline: "",
                            value: _vm.sub_text,
                            rules: _vm.subTextRules(!_vm.shouldValidate)
                          },
                          on: {
                            input: function($event) {
                              return _vm.$emit("update:sub_text", $event)
                            }
                          }
                        }),
                        _c("div", { staticStyle: { "margin-left": "415px" } }, [
                          _vm._v(
                            _vm._s(_vm.getWordCount(_vm.sub_text)) + "/120"
                          )
                        ])
                      ],
                      1
                    ),
                    _c(VFlex, { attrs: { xs4: "" } })
                  ],
                  1
                ),
                _c(
                  VLayout,
                  { staticStyle: { "margin-top": "95px" } },
                  [
                    _c(
                      VFlex,
                      { attrs: { xs8: "" } },
                      [
                        _c(VTextField, {
                          attrs: {
                            label: "Button Text",
                            placeholder: "Dismiss",
                            value: _vm.button_text
                          },
                          on: {
                            input: function($event) {
                              return _vm.$emit("update:button_text", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(VFlex, { attrs: { xs4: "" } })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              VFlex,
              { attrs: { xs4: "" } },
              [
                _c(
                  VLayout,
                  {
                    staticClass: "phonepreview",
                    style: {
                      marginTop: "30px",
                      height: "100%",
                      backgroundImage:
                        "url(" + require("@/assets/i-phone-xs-max.svg") + ")"
                    },
                    attrs: { row: "", wrap: "" }
                  },
                  [
                    _c(
                      VFlex,
                      { attrs: { xs12: "", md12: "" } },
                      [
                        _c(
                          VLayout,
                          {
                            staticStyle: { "margin-top": "60px" },
                            attrs: { row: "", warp: "", "justify-center": "" }
                          },
                          [
                            _c(
                              "croppa",
                              {
                                attrs: {
                                  width: 250,
                                  height: 117,
                                  quality: 3,
                                  "prevent-white-space": true,
                                  "remove-button-color": "black",
                                  placeholder:
                                    "Upload Dimensions: 348x148. Max 5mb",
                                  "placeholder-font-size": 12,
                                  "show-remove-button": ""
                                },
                                on: {
                                  "file-type-mismatch": _vm.imageIssue,
                                  "file-choose": _vm.imageChanged,
                                  move: _vm.imageChanged,
                                  zoom: _vm.imageChanged,
                                  "image-remove": _vm.imageRemove
                                },
                                model: {
                                  value: _vm.mycroppa,
                                  callback: function($$v) {
                                    _vm.mycroppa = $$v
                                  },
                                  expression: "mycroppa"
                                }
                              },
                              [
                                !!_vm.image_url
                                  ? _c("img", {
                                      attrs: {
                                        slot: "initial",
                                        src: _vm.image_url,
                                        alt: "announcement image"
                                      },
                                      slot: "initial"
                                    })
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      VFlex,
                      { attrs: { xs12: "", md12: "" } },
                      [
                        _c(
                          VLayout,
                          {
                            staticStyle: { "margin-top": "-175px" },
                            attrs: { row: "", warp: "", "justify-center": "" }
                          },
                          [
                            _c("div", { staticStyle: { width: "250px" } }, [
                              _c(
                                "span",
                                { staticClass: "H5-Black-High-Emphasis-Left" },
                                [_vm._v("Description")]
                              ),
                              _c("br")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
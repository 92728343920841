<template>
  <v-card style="min-height: 400px;">
    <div class="v-card-content">
      <v-layout row wrap>
        <v-flex xs8>
          <v-layout justify-space-between>
            <v-flex>
              <div class="H5-Primary-Left">{{ language }} Version</div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs8>
              <v-text-field
                label="Title"
                :value="title"
                :rules="titleRules(!shouldValidate)"
                @input="$emit('update:title', $event)"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 />
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 style="margin-bottom: -15px; margin-left: 10px;">Description</v-flex>
            <v-flex xs8 style="height: 68px; padding-bottom: 0px;">
              <v-textarea
                outline
                style="border-radius: 0px !important; padding-bottom: 15px; margin-bottom: -70px;"
                :value="description"
                @input="$emit('update:description', $event)"
                :rules="descriptionRules(!shouldValidate)"
              />
              <div style="margin-left: 415px;">{{ getWordCount(description) }}/200</div>
            </v-flex>
            <v-flex xs4 />
          </v-layout>
          <v-layout row wrap style="margin-top: 75px;">
            <v-flex xs12 style="margin-bottom: -15px; margin-left: 10px;">Sub Text</v-flex>
            <v-flex xs8 style="height: 68px; padding-bottom: 0px;">
              <v-textarea
                outline
                style="border-radius: 0px !important; padding-bottom: 15px; margin-bottom: -70px;"
                :value="sub_text"
                @input="$emit('update:sub_text', $event)"
                :rules="subTextRules(!shouldValidate)"
              />
              <div style="margin-left: 415px;">{{ getWordCount(sub_text) }}/120</div>
            </v-flex>
            <v-flex xs4 />
          </v-layout>
          <v-layout style="margin-top: 95px;">
            <v-flex xs8>
              <v-text-field
                label="Button Text"
                placeholder="Dismiss"
                :value="button_text"
                @input="$emit('update:button_text', $event)"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 />
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-layout
            row
            wrap
            class="phonepreview"
            :style="{
              marginTop: '30px',
              height: '100%',
              backgroundImage: 'url(' + require('@/assets/i-phone-xs-max.svg') + ')',
            }"
          >
            <v-flex xs12 md12>
              <v-layout row warp justify-center style="margin-top: 60px;">
                <croppa
                  v-model="mycroppa"
                  :width="250"
                  :height="117"
                  :quality="3"
                  :prevent-white-space="true"
                  remove-button-color="black"
                  :placeholder="`Upload Dimensions: 348x148. Max 5mb`"
                  :placeholder-font-size="12"
                  show-remove-button
                  @file-type-mismatch="imageIssue"
                  @file-choose="imageChanged"
                  @move="imageChanged"
                  @zoom="imageChanged"
                  @image-remove="imageRemove"
                >
                  <img
                    v-if="!!image_url"
                    :src="image_url"
                    slot="initial"
                    alt="announcement image"
                  />
                </croppa>
              </v-layout>
            </v-flex>
            <v-flex xs12 md12>
              <v-layout row warp justify-center style="margin-top: -175px;">
                <div style="width: 250px;">
                  <span class="H5-Black-High-Emphasis-Left">Description</span>
                  <br />
                </div>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
// import cloneDeep from 'lodash/cloneDeep';
// import { mapGetters, mapState } from 'vuex';
import rules from '@/rules';

export default {
  name: 'announcement-language',
  props: {
    image_url: {
      default: () => '',
      type: String,
    },
    image_data: {
      default: () => '',
      type: String,
    },
    title: {
      default: () => '',
      type: String,
    },
    description: {
      default: () => '',
      type: String,
    },
    sub_text: {
      default: () => '',
      type: String,
    },
    button_text: {
      default: () => '',
      type: String,
    },
    language: {
      default: () => 'English',
      type: String,
    },
  },
  data: () => ({
    titleRules: (unlessCondition) => [
      rules.requiredUnless('Title is a required field', unlessCondition),
    ],
    descriptionRules: (unlessCondition) => [
      rules.requiredUnless('Description is a required field', unlessCondition),
      rules.maxWords(
        200,
        'Description must be less than or equal to 200 words long',
        unlessCondition,
      ),
    ],
    subTextRules: (unlessCondition) => [
      rules.requiredUnless('Sub Text is a required field', unlessCondition),
      rules.maxWords(120, 'Sub Text must be less than or equal to 120 words long', unlessCondition),
    ],
    mycroppa: {},
  }),
  watch: {
    image_url() {
      this.mycroppa.refresh();
    },
    image_data() {
      this.mycroppa.refresh();
    },
    shouldValidate(v) {
      if (!v) {
        this.setAllProps('');
        setTimeout(() => {
          this.setAllProps(null);
        }, 25);
      }
    },
  },
  computed: {
    shouldValidate() {
      const { image_url, title, description, button_text, image_data } = this;
      return !!(
        image_data ||
        image_url ||
        title ||
        description ||
        (button_text && button_text !== 'Dismiss')
      );
    },
  },
  mounted() {},
  methods: {
    setAllProps(v) {
      this.$emit('update:button_text', v);
      this.$emit('update:description', v);
      this.$emit('update:image_url', v);
      this.$emit('update:sub_text', v);
      this.$emit('update:title', v);
    },
    getWordCount(w) {
      if (!w || typeof w !== 'string') return 0;
      return w.trim().split(/\s+/).length;
    },
    imageChanged(image) {
      this.altered = true;
      if (image) {
        this.$emit('update:image_data', `${image.name}-${image.lastModified}-${image.size}`);
      }
    },
    imageRemove() {
      this.altered = true;
      this.$emit('update:image_url', null);
      this.$emit('update:image_data', null);
    },
    imageIssue() {
      this.$toast('Invalid file type, choose a jpg or png file.');
    },
    async save() {
      // determine if location has been changed
      if (this.altered) {
        const base64 = this.mycroppa.generateDataUrl();
        if (base64) {
          const { data } = await this.api.post('/file', {
            file_base64: base64.replace('data:image/png;base64,', ''),
          });
          this.$emit('update:image_url', data.url);
        } else {
          this.$emit('update:image_url', this.image_url);
        }
      }
    },
  },
};
</script>

<style>
textarea {
  margin-top: 0px !important;
  padding-bottom: 24px !important;
}

.phonepreview {
  background-position: center top;
  margin-top: 10px;
}
</style>

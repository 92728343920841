<template>
  <v-card style="min-height: 400px">
    <div class="v-card-content">
      <v-layout>
        <v-flex xs9>
          <v-layout justify-space-between>
            <v-flex>
              <div class="H5-Primary-Left">Announcement Details</div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs8>
              <v-text-field
                label="Announcement Name"
                :value="name"
                :rules="nameRules"
                @input="$emit('update:name', $event)"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 />
            <v-flex xs6>
              <v-select
                label="Announcement Type"
                :value="isAnnouncementTypeOther ? 'Other' : announcement_type"
                :rules="typeRules"
                @input="updateAnnouncementType($event)"
                :items="announcementTypes"
              />
              <v-text-field
                v-if="isAnnouncementTypeOther"
                label="Type"
                :value="announcement_type"
                :rules="typeRules"
                @input="$emit('update:announcement_type', $event)"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 />
            <v-flex xs6>
              <v-select
                label="Select App"
                :value="resource_key"
                :rules="appRules"
                @input="updateResourceKey($event)"
                :items="availableApps"
              />
            </v-flex>
            <v-flex xs6 />
            <v-flex xs8>
              <v-layout row wrap v-if="resource_key">
                <div style="margin-left: 25px">Sites</div>
                <v-flex xs12 style="height: 68px; padding-bottom: 0px">
                  <div id="site-search">
                    <v-text-field
                      :label="`Search Sites (${app})`"
                      prepend-inner-icon="mdi-magnify"
                      outline
                      style="border-radius: 0px !important"
                      v-model="search"
                      clearable
                    />
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-layout row style="padding-left: 12px; padding-right: 12px">
                    <div style="overflow: hidden; border: solid 1px #757575; width: 100%">
                      <v-list class="list-panel">
                        <v-list-tile>
                          <v-list-tile-action>
                            <v-checkbox
                              v-model="isGlobalProxy"
                              :value="isGlobalProxy"
                              label="Select All"
                            />
                          </v-list-tile-action>
                        </v-list-tile>
                        <v-list-tile
                          v-for="(site, siteIndex) of orderBy(
                            filterBy(availableSites, search, 'name'),
                            'name',
                          )"
                          @click="() => {}"
                          :key="site.id"
                          :id="siteIndex"
                        >
                          <v-list-tile-action>
                            <v-checkbox
                              v-model="allowedResourcesProxy"
                              :value="site.id"
                              :label="site.name"
                              @change="updateIsGlobal"
                            />
                          </v-list-tile-action>
                        </v-list-tile>
                      </v-list>
                    </div>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4 />
            <v-flex xs6>
              <div v-if="resource_key">
                <v-select
                  label="Position"
                  :disabled="!app"
                  :value="this.position"
                  :rules="positionRules"
                  @input="$emit('update:position', $event)"
                  :items="availablePositions"
                  v-if="availablePositions.length > 0 && (active || isNewAnnouncement)"
                />
                <div v-else>
                  Enable this announcement to see positions. If not possible, disable another one.
                </div>
              </div>
            </v-flex>
            <v-flex xs6 />
            <v-flex xs6>
              <v-select
                label="Status"
                :disabled="availablePositions.length === 0"
                :value="active"
                @input="updateStatus($event)"
                :items="announcementActiveStates"
              />
            </v-flex>
            <v-flex xs6 />
          </v-layout>
        </v-flex>
        <v-flex xs3 />
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import rules from '@/rules';
import filters from 'vue2-filters';

export default {
  name: 'announcement-details',
  mixins: [filters.mixin],
  props: {
    name: {
      default: () => '',
      type: String,
    },
    announcement_type: {
      default: () => null,
      type: String,
    },
    app: {
      default: () => '',
      type: String,
    },
    allowed_resources: {
      default: () => [],
      type: Array,
    },
    position: {
      type: Number,
    },
    is_global: {
      default: () => false,
      type: Boolean,
    },
    resource_key: {
      default: () => '',
      type: String,
    },
    active: {
      default: () => true,
      type: Boolean,
    },
    isNewAnnouncement: {
      default: () => true,
      type: Boolean,
    },
    availableApps: {
      default: () => [],
      type: Array,
    },
    multigroupMap: {
      defeault: () => {},
      type: Object,
    },
  },
  data: () => ({
    search: '',
    nameRules: [rules.required('Announcement Name is a required field')],
    typeRules: [rules.required('Announcement Type is a required field')],
    appRules: [rules.required('Announcement App is a required field')],
    positionRules: [rules.requiredNonFalsey('Announcement Position is a required field')],
    announcementTypes: [
      'Promotions',
      'App Feature',
      'Menu Feature',
      'Location-Specific Feature',
      'Co-Branding',
      'Other',
    ],
    announcementActiveStates: [
      {
        text: 'Active',
        value: true,
      },
      {
        text: 'Inactive',
        value: false,
      },
    ],
    backupPosition: -1,
    backupResourceKey: null,
  }),
  computed: {
    ...mapGetters('announcements', {
      getAnnouncements: 'getAnnouncements',
    }),
    ...mapState('sites', ['siteMap']),
    ...mapGetters('adminPanel', ['isAdmin']),
    isGlobalProxy: {
      get() {
        return this.is_global;
      },
      set(v) {
        const is_global = v;
        this.$emit('update:is_global', is_global);
        if (!is_global) this.$emit('update:allowed_resources', []);
        else
          this.$emit(
            'update:allowed_resources',
            this.availableSites.map((s) => s.id),
          );
      },
    },
    allowedResourcesProxy: {
      get() {
        return this.allowed_resources;
      },
      set(v) {
        this.$emit('update:allowed_resources', v);
      },
    },
    isAnnouncementTypeOther() {
      return (
        this.announcement_type !== null &&
        !this.announcementTypes.some((a) => a === this.announcement_type)
      );
    },
    availablePositions() {
      if (this.resource_key) {
        return this.computeAvailablePositions();
      }
      return [];
    },
    availableSites() {
      if (this.resource_key) {
        if (this.isAdmin) return this.multigroupMap[this.resource_key].groups;
        return this.multigroupMap[this.resource_key].groups.filter((cg) => this.siteMap[cg.id]);
      }
      return null;
    },
  },
  mounted() {
    if (this.is_global) {
      this.$emit(
        'update:allowed_resources',
        this.availableSites.map((s) => s.id),
      );
    }
    if (this.availablePositions.length === 0) {
      if (this.isNewAnnouncement) {
        this.$emit('update:active', false);
        this.$emit('update:position', 0);
      }
      if (!this.isNewAnnouncement && !this.active) {
        this.$emit('update:position', this.position);
      }
    }
    this.$set(this, 'backupPosition', this.position);
    this.$set(this, 'backupResourceKey', this.resource_key);
  },
  methods: {
    computeAvailablePositions() {
      return [1, 2, 3, 4, 5].map((p) => ({
        text: p,
        value: p,
      }));
    },
    updateResourceKey(v) {
      this.$emit('update:resource_key', v);
      this.$emit('update:app', this.multigroupMap[v].name);
      this.$emit('update:is_global', false);
      this.$emit('update:position', 0);
      this.$emit('update:allowed_resources', []);
    },
    updateStatus(v) {
      this.$emit('update:active', v);
      if (!v) {
        this.$emit('update:position', 0);
      }
    },
    updateAnnouncementType(v) {
      this.$emit('update:announcement_type', v === 'Other' ? '' : v);
    },
    updateIsGlobal(v) {
      this.$emit('update:is_global', this.availableSites.length === v.length);
    },
  },
};
</script>

<style>
.list-panel {
  height: 20vh;
  overflow: scroll;
}
#site-search > .v-input__slot {
  border-radius: 0px;
}

#site-search > .theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
  margin-bottom: 0px;
}

#site-search > .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
</style>

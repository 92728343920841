import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(VFlex, { attrs: { xs4: "", right: "" } }, [
        !_vm.isNewAnnouncement
          ? _c(
              "div",
              {
                staticClass: "FAB-Extended-PrimaryColor-Enabled",
                staticStyle: { "margin-top": "15px" }
              },
              [
                _c(
                  VBtn,
                  {
                    attrs: { type: "submit", color: "primary" },
                    on: { click: _vm.deleteAnnouncement }
                  },
                  [
                    _c(VIcon, { staticClass: "mr-1", attrs: { left: "" } }, [
                      _vm._v("mdi-delete-outline")
                    ]),
                    _vm._v("DELETE ANNOUNCEMENT\n      ")
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      !_vm.loading
        ? _c(
            VForm,
            {
              ref: "form",
              staticClass: "settings",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(VFlex, { attrs: { xs8: "" } }, [_c("view-title")], 1),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c("announcement-details", {
                        attrs: {
                          name: _vm.announcement.name,
                          announcement_type: _vm.announcement.type,
                          app: _vm.announcement.app,
                          allowed_resources: _vm.allowedResourcesProxy,
                          position: _vm.announcement.position,
                          is_global: _vm.announcement.is_global,
                          resource_key: _vm.announcement.key,
                          active: _vm.announcement.active,
                          isNewAnnouncement: _vm.isNewAnnouncement,
                          availableApps: _vm.availableApps,
                          multigroupMap: _vm.multigroupMap
                        },
                        on: {
                          "update:name": function($event) {
                            return _vm.$set(_vm.announcement, "name", $event)
                          },
                          "update:announcement_type": function($event) {
                            return _vm.$set(_vm.announcement, "type", $event)
                          },
                          "update:app": function($event) {
                            return _vm.$set(_vm.announcement, "app", $event)
                          },
                          "update:allowed_resources": function($event) {
                            _vm.allowedResourcesProxy = $event
                          },
                          "update:position": function($event) {
                            return _vm.$set(
                              _vm.announcement,
                              "position",
                              $event
                            )
                          },
                          "update:is_global": function($event) {
                            return _vm.$set(
                              _vm.announcement,
                              "is_global",
                              $event
                            )
                          },
                          "update:resource_key": function($event) {
                            return _vm.$set(_vm.announcement, "key", $event)
                          },
                          "update:active": function($event) {
                            return _vm.$set(_vm.announcement, "active", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c("language-info", {
                        ref: "englishVersion",
                        attrs: {
                          language: "English",
                          image_url: _vm.announcement.info.en.image_url,
                          image_data: _vm.images.en,
                          title: _vm.announcement.info.en.title,
                          description: _vm.announcement.info.en.description,
                          sub_text: _vm.announcement.info.en.sub_text,
                          button_text: _vm.announcement.info.en.button_text
                        },
                        on: {
                          "update:image_url": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.en,
                              "image_url",
                              $event
                            )
                          },
                          "update:image_data": function($event) {
                            return _vm.$set(_vm.images, "en", $event)
                          },
                          "update:title": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.en,
                              "title",
                              $event
                            )
                          },
                          "update:description": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.en,
                              "description",
                              $event
                            )
                          },
                          "update:sub_text": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.en,
                              "sub_text",
                              $event
                            )
                          },
                          "update:button_text": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.en,
                              "button_text",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    {
                      staticStyle: { "margin-bottom": "100px" },
                      attrs: { xs12: "" }
                    },
                    [
                      _c("language-info", {
                        ref: "frenchVersion",
                        attrs: {
                          language: "French",
                          image_url: _vm.announcement.info.fr.image_url,
                          image_data: _vm.images.fr,
                          title: _vm.announcement.info.fr.title,
                          description: _vm.announcement.info.fr.description,
                          sub_text: _vm.announcement.info.fr.sub_text,
                          button_text: _vm.announcement.info.fr.button_text
                        },
                        on: {
                          "update:image_url": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.fr,
                              "image_url",
                              $event
                            )
                          },
                          "update:image_data": function($event) {
                            return _vm.$set(_vm.images, "fr", $event)
                          },
                          "update:title": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.fr,
                              "title",
                              $event
                            )
                          },
                          "update:description": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.fr,
                              "description",
                              $event
                            )
                          },
                          "update:sub_text": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.fr,
                              "sub_text",
                              $event
                            )
                          },
                          "update:button_text": function($event) {
                            return _vm.$set(
                              _vm.announcement.info.fr,
                              "button_text",
                              $event
                            )
                          }
                        }
                      }),
                      _c("span", [
                        _vm._v(
                          "\n          Only 5 announcements can be created per location for a given time period. If you exceed\n          this limit, it will not show up in the app.\n        "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("save-footer", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isModified,
                    expression: "isModified"
                  }
                ],
                attrs: {
                  cancelAction: _vm.cancel,
                  saveLabel: _vm.isNewAnnouncement
                    ? "Create Announcement"
                    : "Save Changes",
                  saveAction: _vm.save
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }